import { template as template_910d3128295b4c65a2c9e03c87f3db1e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_910d3128295b4c65a2c9e03c87f3db1e(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
