import { template as template_4f869f58091d4f829d2b6e4b20a215ac } from "@ember/template-compiler";
import UserLink from "discourse/components/user-link";
import avatar from "discourse/helpers/avatar";
import icon from "discourse-common/helpers/d-icon";
const ReviewableCreatedBy = template_4f869f58091d4f829d2b6e4b20a215ac(`
  <div class="created-by">
    {{#if @user}}
      <UserLink @user={{@user}}>{{avatar @user imageSize="large"}}</UserLink>
    {{else}}
      {{icon "trash-can" class="deleted-user-avatar"}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ReviewableCreatedBy;
