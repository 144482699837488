import { template as template_f09b6d7df0ce404f900ceb4945ca9638 } from "@ember/template-compiler";
const FKLabel = template_f09b6d7df0ce404f900ceb4945ca9638(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
